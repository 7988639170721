/* src/ClassDetails.css */
.class-details {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content-class {
  padding: 20px;
   background-color: rgb(247, 247, 247); 
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* margin-top: 20px; */
  flex: 1;
  overflow-y: auto;
  /* width: 70%; */
  display: flex;
  flex-direction: column;
  /* margin-left: 15%; */
}

.student-content {
  width: 70%;
  margin-left:15%;
  background-color: white;
  border-radius: 10px;
  padding-top: 10px;
  padding-right: 10px;
}

.student-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.sort-controls {
  padding-top: 10px;
  display: flex;
  align-items: center;
}

.sort-controls label {
  margin-right: 10px;
  padding-left: 10px;
  font-weight: bold;
  color: darkgray
}

.sort-controls select {
  margin-right: 10px;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 14px;
}

.student-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.student-item {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
}

.student-avatar {
  width: 40px;
  height: 40px;
  background-color: #2c3e50;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: 20px;
  font-weight: bold;
  font-size: 18px;
}

.student-info {
  flex: 1;
}

.student-actions {
  display: flex;
}

.edit-button, .unenroll-button {
  padding: 5px 10px;
  margin-left: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.edit-button {
  background-color: #f0f0f0;
}

.unenroll-button {
  background-color: #e74c3c;
  color: white;
}

.assessment-cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  padding: 10px;
  justify-content: flex-start;
}

.assessment-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 15px;
  margin: 10px;
  width: 250px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.mobile-tabs {
  display: none; /* Hide by default */
}

.student-id-id {
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.student-id-string {
  margin-top: 5px;
  margin-bottom: 5px;
}

/* Add these new styles at the end of the file */
@media (max-width: 768px) {
  .content-class {
    padding: 5px;
  }
  .assessment-cards-container{
    gap: 0;
    padding: 0;
  }

  .assessment-card {
    transform: scale(0.85);
    margin: 0;
    padding: 10px;
    max-width: 160px;
  }

  .footer {
    display: none;
  }

  .class-details {
    padding-bottom: 60px; /* Make room for the bottom tabs */

  }

  .mobile-tabs {
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #34495e;
    padding: 10px 0;
    z-index: 1000;
  }

  .mobile-tab-button {
    color: white;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }

  .mobile-tab-button.active {
    color: #e67e22;
  }

  .student-content {
    margin-left: 0;
    width: 100%;
    padding-right: 0;
  }
  .sort-controls{
    display: none;
  }
  .manage-students-dropdown{
    width: 100%;
    margin-right: 5%;
  }
  .dropdown-button{
    width: 100%;
  }
  .student-item{
    padding:5px;
  }
  .student-id-string {
    display: none;
  }
  .student-id-id {
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .unenroll-button{
    padding-top: 10px;
    padding-bottom: 10px;
  }
}