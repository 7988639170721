.acontainer {
  display: flex;
  flex-direction: row;
  height: 85vh;  
  overflow: hidden;
}

.student-sidebar {
  width: 80px;
  background-color: #f8f9fa;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
  border-right: 1px solid #2c3e50;
  overflow-y: auto;
  height: 100%;
}

.student-initials {
  width: 40px;
  height: 40px;
  min-height: 40px;
  background-color: #007bff;
  color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  margin-bottom: 10px;
  cursor: pointer;
  flex-shrink: 0;
}

.student-initials.active {
  background-color: #ffbb33;
}

.student-info-details {
width: 200px;
padding: 20px;
background-color: #f8f9fa;
border-right: 1px solid #ddd;
}

.student-avatar-details {
position: relative;
top: -40px;
left: 70px;
width: 70px;
height: 70px;
background-color: #007bff;
color: #fff;
border-radius: 50%;
display: flex;
align-items: center;
justify-content: center;
font-size: 1.5em;
margin-bottom: 15px;
}

.student-ids{
  display: flex;
    flex-direction: column;
    align-items: center; /* Center horizontally */
    text-align: center;
}

.overall-grade {
margin-top: 20px;
}

.print-button {
margin-top: 15px;
padding: 8px 12px;
background-color: #007bff;
color: #fff;
border: none;
border-radius: 5px;
cursor: pointer;
}

.timeline {
flex: 1;
padding: 20px;
/* padding-top: 60px; */
background-color: #fff;
overflow-y: auto;
}

.timeline-item {
margin-bottom: 20px;
position: relative;
padding-left: 25px;
}

.timeline-item::before {
content: '';
position: absolute;
left: 0;
top: 0;
width: 10px;
height: 10px;
background-color: #007bff;
border-radius: 50%;
}

.timeline-date {
font-size: 0.9em;
color: #777;
margin-bottom: 5px;
}

.timeline-content {
margin-left: 10px;
background-color: #ecf0f1;
padding: 15px;
padding-bottom: 5px;
border-radius: 5px;
box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.category-tag {
display: inline-block;
background-color: #dff0d8;
color: #3c763d;
padding: 3px 8px;
border-radius: 3px;
font-size: 0.9em;
margin-bottom: 5px;
}

.add-assessment-onstudent-button {
position: relative;
left: -10px;
top: -50px;
padding: 10px 15px;
background-color: #007bff;
color: #fff;
border: none;
border-radius: 50%;
font-size: 1.2em;
cursor: pointer;
}

.close-icon {
  position: relative;
  top: -10px;
  left: 0px;
  width: 30px;
  height: 30px;
  background-color: transparent;
  color: #000;
  border: 2px solid #ccc;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.close-icon:hover {
  background-color: #f2f2f2;
}

.ungraded-label {
  color: lightgray;
  font-size: 1.2em; /* Adjust the font size if necessary */
  font-weight: bold; /* Make the text bold */
  position: relative;
  right: 10px; /* Adjust the position as needed */
  /* margin-top: 10px; */
}

.graded-label {
  color: black;
  font-size: 1.2em; /* Adjust the font size if necessary */
  font-weight: bold; /* Make the text bold */
  position: relative;
  right: 10px; /* Adjust the position as needed */
  /* margin-top: 10px; */
}

.student-p {
  margin-bottom: 10px;
}

@media (max-width: 768px) {

  .student-sidebar {
    width: 50px;
  }
  .student-avatar-details{
    display: none;
  }
  .student-info-details {
    width: 80px;
  }
  .timeline{
    padding: 5px;
  }
  .timeline-item{
    padding-left: 0px;
  }
  .timeline-item::before {
    display: none;
  }
  .ungraded-label {
    font-size: 12px;
    right: 0;
  }
}

.add-observation-button {
  position: sticky;
  top: 0px;
  z-index: 10;
  margin-left: 0px;
  width: 35px;
  height: 40px;
  border-radius: 50%;
  background-color: #3498db;
  color: white;
  font-size: 24px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
  margin-bottom: 10px;
}

.add-observation-button:hover {
  background-color: #2980b9;
}

.timeline.containers {
  padding-top: 5px;
  padding-left: 10px;
  position: relative;
}