/* src/ManageStudentsDropdown.css */
.manage-students-dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-button {
    background-color: #2c3e50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .dropdown-menu {
    position: absolute;
    right: 0;
    top: 100%;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    list-style: none;
    padding: 0;
    margin: 5px 0 0;
    width: 200px;
  }
  
  .dropdown-item {
    padding: 10px;
    cursor: pointer;
    border: none;
    background: none;
    text-align: left;
    width: 100%;
    font-size: 14px;
  }
  
  .dropdown-item:hover {
    background-color: #f4f4f4;
  }
  