/* src/AssessmentModal.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  padding-top: 35px;
  z-index: 1000;
}

.assessment-modal {
  background: #2c3e50;
  border-radius: 8px;
  width: 50%;
  max-height: 70vh;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  color: white;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-right: 25%;

}

.modal-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-left: 20px;
  /* background-color: #34495e; */
}

.close-button {
  background: none;
  border: none;
  font-size: 24px;
  color: white;
  cursor: pointer;
}

.modal-body {
  display: flex;
  flex: 1;
  overflow-y: auto;
}

.tab-menu-vertical {
  display: flex;
  flex-direction: column;
  width: 150px;
  background-color: #34495e;
  padding: 10px 0;
}

.tab-menu-vertical button {
  background: none;
  border: none;
  color: #bdc3c7;
  font-size: 16px;
  cursor: pointer;
  padding: 10px 15px;
  text-align: left;
}

.tab-menu-vertical .active {
  color: white;
  background-color: #2c3e50;
}

.tab-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.general-tab .input-group,
.standards-tab,
.attachments-tab,
.lessons-tab {
  margin-bottom: 15px;
}

.input-group label {
  display: block;
  font-size: small;
  margin-bottom: 5px;
}

.input-group input {
  width: 30px;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 14px;
}

.input-group select {
  width: 80%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 14px;
}

.input-group input[type="radio"],
.input-group input[type="checkbox"] {
  width: auto;
}

.upload-button {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  padding: 10px 20px;
  background-color: #34495e;
}

.cancel-button,
.create-button {
  padding: 10px 15px;
  margin-left: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.cancel-button {
  background-color: #e74c3c;
  color: white;
}

.create-button {
  background-color: #2ecc71;
  color: white;
}

.input-div-outof{
  width:"15%";
  margin-left:"10%"
}
.due-date-class{
  width: "25%";
  min-width: 120px;

  margin-top: "10px";
  margin-bottom: "10px";
}

.tab-menu-vertical{
  width: 30%;
}