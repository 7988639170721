.modal-overlay-clone {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal-content-clone {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    max-width: 500px;
    width: 100%;
  }
  
  .class-list-clone {
    max-height: 300px;
    overflow-y: auto;
    margin-bottom: 20px;
  }
  
  .class-item-clone {
    margin-bottom: 10px;
  }
  
  .modal-actions-clone {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
  
  .error-message-clone {
    color: red;
    margin-bottom: 10px;
  }


@media (max-width: 768px) {
    .modal-content-clone{
      width: 60%;
      max-width: 60%;
      margin-right: 15%;
    }
    .clone-title{
      word-break: break-word;
      font-size: 16px;
    }
    .modal-overlay-clone{
      /* right: auto; */
    }
}