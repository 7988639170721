/* src/ClassHeader.css */
.class-header {
  display: flex;
  align-items: center;
  background-color: #34495e;
  padding: 10px 20px;
  color: white;
}

.class-dropdown {
  position: relative;
  margin-right: 20px;
}

.dropdown-header {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 4px;
  background-color: #2c3e50;
}

.current-class {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}

.class-name {
  font-weight: bold;
}

.class-section {
  font-size: 0.8em;
  color: #bdc3c7;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #2c3e50;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 10;
  min-width: 200px;
}

.dropdown-item-class {
  background-color: #34495e;
  padding: 10px;
  width: 180px;
  max-width: 180px;
  cursor: pointer;
}

.dropdown-item-class:hover {
  background-color: #6d9ac8;
  padding-right: 10px;
}

.dropdown-item-class.active {
  background-color: #3498db;
}

.tabs {
  display: flex;
}

.tab-button {
  background-color: transparent;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
}

.tab-button.active {
  border-bottom: 2px solid #e67e22;
}

@media (max-width: 768px) {
  .desktop-only {
    display: none;
  }

  .class-header {
    justify-content: center;
  }

  .class-dropdown {
    width: 100%;
    margin-right: 0;
  }
}