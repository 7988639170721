/* src/AssessmentDetails.css */

.assessment-details {
    /* padding: 20px; */
    background-color: #f9f9f9;
  }
  
  .header-asd {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f9f9f9;
    padding-left: 3%;
    padding-right: 3%;
    width: 100%;
    /* margin-bottom: 20px; */
  }
  
  .header-asd h1 {
    margin: 0;
    font-size: 1.5rem;
    max-width: 300px;
    white-space: nowrap;
    /* margin-left: 7%; */
    margin-right: 10%;
  }
  
  .date {
    font-size: 1rem;
    padding-top: 5px;
    padding-right: 10px;
    color: #888;

  }
  
  .tag {
    background-color: #007bff;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 0.9rem;
    margin-left:10px;
  }
  
  .controls {
    background-color: #f1f1f1;
    width: 70%;
    padding-left:15%;
    padding-right: 15%;
    padding-bottom: 20px;
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin-bottom: 20px; */
  }
  
  .sort-controls select {
    margin-right: 10px;
    padding: 5px;
  }
  
  .bulk-grade-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .students-container {
    background-color: #f1f1f1;
    overflow: auto;
    width: 70%;
    height: 70vh;
    padding-left:15%;
    padding-right: 15%;
    display: flex;
    padding-bottom: 50px; 
    flex-direction: column;
  }
  
  .student-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    /* margin-bottom: 10px; */
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .student-header {
    display: flex;
    align-items: center;
    padding-right: 20px;
  }
  
  .student-avatar {
    background-color: #007bff;
    color: #fff;
    width: 40px;
    height: 40px;
    margin-top: 7.5px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    font-size: 1.2rem;
  }
  
  .student-name {
    flex-grow: 1;
    font-weight: bold;
  }
  
  input[type="number"] {
    width: 60px;
    padding: 5px;
    text-align: right;
    margin-right: 15px;
  }
  
  .mark-as-button {
    background-color: #e67e22;
    color: #fff;
    border: none;
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .student-details {
    margin-top: 15px;
  }
  
  textarea {
    margin-left: 1%;
    width: 85%;
    max-width: 95%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
    margin-bottom: 10px;
    max-height: 50px;
  }
  
  .attachments {
    display: flex;
    align-items: center;
  }
  
  .upload-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 10px;
  }
  
  .save-button {
    background-color: #28a745;
    color: #fff;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    display: block;
    width: 100%;
  }
  
  .tabs-ad {
    display: flex;
    border-bottom: 1px solid #ddd;
    margin-bottom: 10px;
    /* width: 40%; */
    padding-left: 30%;
    padding-right: 30%;
  }
  
  .tab {
    padding: 10px 20px;
    cursor: pointer;
    font-weight: bold;
    color: #555;
  }
  
  .tab.active {
    border-bottom: 3px solid #4CAF50;
    color: #000;
  }
  
  .student-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }
  
  .student-name {
    font-weight: bold;
    flex-grow: 1;
  }
  
  .mark-as-button {
    margin-left: 15px;
  }
  
  .student-details {
    margin-top: 10px;
  }

  .score-field :hover {
    border: 1px solid #000;
  }
  .score-field {
    display: inline-block;
    cursor: pointer;
    text-align: right;  /* Align text to the right */
    width: 100px;  /* Ensure the field takes up the full width available */
  }
  
  .score-display {
    font-weight: bold;
    color: #4CAF50; /* Green color when score is present */
    display: inline-block;
    text-align: right;  /* Align text to the right */
    width: 100%;  /* Ensure the text takes up the full width */
  }
  
  .score-input {
    width: 60px;
    font-size: 1em;
    text-align: right;  /* Align input text to the right */
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .file-previews {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-top: 10px;
  }
  
  .file-preview {
    display: flex;
    align-items: center;
    text-align: center;
  }
  
  .file-preview span {
    margin-top: 5px;
    font-size: 0.8em;
    word-break: break-word;
  }

  .attachments {
    display: flex;
    justify-content: space-around;
  }

  .tooltip {
    position: relative;
    display: inline-block;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: 140px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -70px;
    opacity: 0;
    transition: opacity 0.3s;
  }

  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }

  .titleDiv{
    display: "flex";
    flex-direction: "column";
    margin-left: "7%";
    margin-right:"10%";
  }

  .btnSpan{
    margin-left: "10px";
  }
  .moreActions{
    display: flex;
    flex-direction: row;
    align-content: center;
    margin-left: 40%;
    margin-right: 5%;
  }

  @media (max-width: 768px) {
    .titleDiv{
      margin-left: 2%;
      margin-right:2%;
    }
    .moreActions {
        margin-left: 2px;
        margin-right: 2px;
        padding-left:0px;
        padding-right: 0px;
    }
    .savingSpan{
      display: none;
    }

    .btnSpan {
      margin-left: "0px";
      min-width: 30px;
      max-width: 40px;
    }

    .buttonSavingContainer {
      max-width: 40px;
      width: 40px;
      overflow: hidden;
      display: flex;
    }

    .buttonSavingContainer button {
      max-width: 100%;
      width: 100%;
      min-width: unset;
    }

    .btnSpan span{
      max-width: 100%;
      width: 100%;
      min-width: unset;
    }

    .header-asd{
      height: 60px;
      padding-left:0px;
      padding-right: 0px;
      margin-right: 0;
      margin-left: 0;
      justify-content: space-between;
    }

    .header-asd h1 {
      font-size: 18px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 150px;
    }
    .bulk-grade-button{
      display: none;
    }
    .controls{
      display: none;
    }
    .students-container{
      padding-top: 4%;
      width: 96%;
      padding-left:2%;
      padding-rigth:2%;
    }

    .tabs-ad {
      padding-left:10%;
    }
  }