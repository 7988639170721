/* Modal overlay to cover the entire screen */
.modal-overlay-delete-category {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Modal content box */
  .modal-content-delete-category {
    background-color: white;
    /* padding: 20px; */
    border-radius: 8px;
    width: 400px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
  }
  
  /* Modal header */
  .modal-content h2 {
    margin-bottom: 20px;
  }
  
  /* Modal select dropdown */
  .modal-content select {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .modal-delete-head{
    width: 100%;
    /* border-radius: 8px; */
    color: white;
    background-color: #1c3753;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}
  
  /* Modal footer with buttons */
  .modal-footer-delete {
    display: flex;
    justify-content: space-between;
  }
  
  /* Delete button */
  .delete-btn {
    background-color: red;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  /* Cancel button */
  .cancel-btn {
    background-color: gray;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  /* Button to open the modal */
  .open-modal-btn {
    padding: 10px 20px;
    background-color: blue;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  .modal-content-delete-category{
    width: 50%;
    border-radius: 8px;
  }

  @media (max-width:768px) {
    .modal-content-delete-category{
      width: 80%;
    }

    .delete-category-title{
      font-size: 18px;
    }
  }