.landing-page {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-position: center;
    color: white;
    text-align: center;
  }
  
  .content {
    background-color: #1c3753;
    padding: 2rem;
    width: 100%;
  }
  
  h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
  }
  
  p {
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }
  .mobileDescription {
    display: none;
  }
  
  .start-button {
    background-color: #1fbb78;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .start-button:hover {
    background-color: #45a049;
  }

  .landing-art {
    opacity: 0.5;
    max-width: '100%';
    height: 'auto';
    margin-bottom: '20px';
  }

  .footer-landing {
    background-color: #f1f1f1;
    color: #333;
    text-align: center;
    border-top: 1px solid #ddd;
    margin-top: auto;
    position: absolute;
    bottom: 0;
  }

  @media (max-width:768px) {
    .content {
      padding-left:0;
    }
    h1 {
      font-size: 25px;
    }
    p {
      font-size: 16px;
    }
    .landing-art {
      max-width: 80%;
    }
    .desktopDescription {
      display: none;
    }
    .mobileDescription {
      display: unset;
    }
  }