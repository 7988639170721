 /* src/CategoriesComponent.css */
.categories-container {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 80%;
    /* margin-left:10%; */
  }
  
  .category-name-input {
    border: none;
    background: none;
  }

  .categories-list {
    width: 50%;
    min-width: 30%;
  }
  
  .category-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .category-color {
    min-width: 20px;
    min-height: 20px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .category-name {
    flex: 1;
    font-weight: bold;
  }
  
  .category-weight-input {
    width: 50px;
    padding: 5px;
    text-align: center;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .remove-button {
    background-color: #e74c3c;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px;
    cursor: pointer;
  }
  
  .add-category-button {
    background-color: #3498db;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
  }

  .add-category-button :disabled {
    background-color: #4e4e4e;
    opacity: 0.3;
    color: rgb(192, 6, 6);
    border: none;
    padding: 10px;
    border-radius: 5px;
    margin-top: 10px;
  }
  
  .total-weight {
    margin-top: 10px;
    font-weight: bold;
  }
  
  .weight-warning {
    color: #e74c3c;
    margin-top: 5px;
  }
  
  .pie-chart-container {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .pie-chart-container p {
    text-align: center;
    margin-top: 10px;
  }
  
  .sync-settings-button {
    margin-top: 10px;
    padding: 8px 16px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  .sync-settings-button:hover {
    background-color: #45a049;
  }

  .sync-settings-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }

.advanced-section {
  width: 80%;
  margin-top: 20px;
}

.toggle-advanced-button {
  background-color: #f0f0f0;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}

.toggle-advanced-button:hover {
  background-color: #e0e0e0;
}

.advanced-options {
  margin-top: 15px;
  padding: 15px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.advanced-options h3 {
  margin-top: 0;
  margin-bottom: 15px;
}


@media (max-width: 768px) {
  .pie-chart-container {
    display: none;
  }
  .category-name-input {
    width: 200px;
    min-width: 150px;
  }
  .category-weight-input {
    min-width: 50px;
  }
  .remove-button{
    padding-left: 10px;
    padding-right: 10px;
  }
}