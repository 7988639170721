/* src/AssessmentCard.css */
.assessment-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 15px;
    margin: 10px;
    width: 250px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .card-date {
    font-size: 0.8em;
    color: #555;
  }
  
  .card-title {
    font-size: 1.2em;
    font-weight: bold;
    margin: 0;
  }
  
  .card-category {
    margin-top: 10px;
  }
  
  .category-tag {
    display: inline-block;
    padding: 5px 10px;
    border-radius: 3px;
    background-color: #eee;
    font-size: 0.9em;
    font-weight: bold;
  }
  
  .card-details {
    margin-top: 15px;
  }
  
  .card-attachment {
    margin-top: 10px;
    font-size: 0.9em;
    color: #777;
  }
  