.toggle-container {
  display: flex;
  width: 80px;
  height: 40px;
  background-color: #e0e0e0;
  border-radius: 20px;
  cursor: pointer;
  overflow: hidden;
}

.toggle-option {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.toggle-option.active {
  background-color: #4caf50; /* Green for checkmark */
}

.toggle-option:last-child.active {
  background-color: #f44336; /* Red for cross */
}

.checkmark, .cross {
  font-size: 24px;
}