/* src/Semesters.css */

.semesters-container {
  /* padding: 20px; */
  width: 100%;
  /* background-color: #f9f9f9; */
}

.error-message {
  color: red;
  /* margin-bottom: 10px; */
  width: 100%;
  text-align: center;
}

.semester-section {
  margin-bottom: 20px;
}

.semester-header {
  background-color: #ffffff;
  height: 50px;
  /* border: 1px solid #ddd; */
  /* border-radius: 5px; */
  /* padding: 15px; */
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  cursor: pointer;
  /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); */
}

.classes-grid {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-top: 10px;
}

.classes-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.class-card {
  /* margin-top: 10px; */
  margin: 15px;
  /* padding: 10px; */
  border-radius: 5px;
  height: 150px;
  width: 200px;
  min-width: 150px;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  color: #555;
  font-weight: bold;
  transition: background-color 0.3s;
}

.class-card-button {
  /* margin-top: 10px; */
  margin: 15px;
  /* padding: 10px; */
  border-radius: 5px;
  height: 150px;
  width: 200px;
  min-width: 150px;
  color: #555;
  font-weight: bold;
  transition: background-color 0.3s;
}

.class-card:nth-child(4n) {
  background-color: #e5ffeb;
}
.class-card:nth-child(4n+1) {
  background-color: #dceffc;
}
.class-card:nth-child(4n+2) {
  background-color: #f2fcde;
}
.class-card:nth-child(4n+3) {
  background-color: #fbd3d3;
}

.class-card:hover {
  /* transform: translateY(-5px); */
}

.class-name {
  font-weight: bold;
}

.class-section {
  color: #ffffff;
  margin-top: 5px;
}

.class-info {
  position: absolute;
  bottom: 10px;
  left: 15px;
  color: #ffffff;
  font-size: 0.9em;
  display: flex;
  flex-direction: column;
}

.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-wrap {
  flex: 1;
}

.footer {
  background-color: #f1f1f1;
  text-align: center;
  padding: 10px 0;
  font-size: 14px;
  color: #333;
  border-top: 1px solid #ddd;
  margin-top: auto;
}

.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-wrap {
  flex: 1;
}

.semesters-container {
  position: relative;
}

.new-semester-button {
  margin-top: 30px;
  width: 15%;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}

.new-semester-button:hover {
  background-color: #0056b3;
}

.modal{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.modal-class-new{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content-semester {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  text-align: center;
}

.modal-content-semester  input {
  width: 80%;
  padding: 8px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.modal-content-semester  button {
  margin-top: 10px;
  padding: 10px 15px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}

.modal-content-semester  button:hover {
  background-color: #0056b3;
}

.modal-content-class {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  text-align: center;
}

.modal-content-class input {
  width: 80%;
  padding: 8px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.okButton {
  margin-top: 10px;
  padding: 10px 15px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}

.cancelButton {
  margin-top: 10px;
  padding: 10px 15px;
  background-color: #ff2049;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}
.modal-content-class  button:hover {
  opacity: 60%;
}
.footer {
  background-color: #f1f1f1;
  text-align: center;
  padding: 10px 0;
  font-size: 14px;
  color: #333;
  border-top: 1px solid #ddd;
  margin-top: auto;
}

.date-selectors-container {
  width: 80%;
  display: flex;
  justify-content: space-between;
}

.date-selector {
  flex: 1;
}

.date-input {
  width: 35%;
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.date-selector label{
  font-size: 12px;
}
.new-class-button {
  /* margin: 15px; */
  /* margin-top: 70px; */

  padding: 10px;
  height: 50px;
  width: 100px;
  /* padding: 8px 16px; */
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  margin-top: 0%;
}

.class-card-button {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
}

.new-class-button:hover {
  background-color: rgb(33, 96, 136);
}

.class-input {
  width: 100%;
  padding: 8px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.modal-open .page-container {
  pointer-events: none;
  user-select: none;
}
.modal-open .modal,
.modal-open .modal-class-new {
  pointer-events: auto;
  user-select: auto;
}

.class-card-header {
  padding: 20px;
  border-radius: 8px 8px 0 0;
}

.class-name {
  font-size: 18px;
  font-weight: bold;
}

.class-card-body {
  background-color: #fdfbfb; /* Light grey background for the body */
  /* padding: 10px; */
  padding-left: 5%;
  padding-bottom: 5%;
  padding-top: 5%;
  color: #333;
  width: 95%;
  border-radius: 0 0 8px 8px;
}

.enrollment-status {
  font-weight: bolder;
  font-size: 12px;
  color: #b1b1b1;
  display: block;
  margin-bottom: 5px;
}

.no-students {
  font-size: 14px;
  color: #3d3d3d;
}

.classes-container {
  margin-top: 10px;
  display: flex;
  /* padding-left: 10px; */
  padding: 10px;
  background-color: #f0f0f0;
  height: 80%;
  grid-auto-flow: column;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

@media (max-width: 768px) {
  .class-card {
    transform: scale(0.8);
    margin: 0;
    width: 170px;
  }
  .class-card-button {
    transform: scale(0.8);
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 170px;
  }
  .classes-container {
    padding: 0px;
    padding-bottom: 10px;
  }
  .new-class-button {
    margin-top: 0%;
  }

  .new-semester-button {
    margin-top: 30px;
    width: 50%;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 4px;
  }
}