/* src/StudentSelectionModal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    z-index: 1000;
  }
  
  .modal-overlay-student {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    z-index: 1000;
  }
  .modal-student {
    
  }
  
  .student-selection-modal {
    background: #2c3e50;
    border-radius: 8px;
    width: 50%;
    height: 70%;
    justify-content: space-between;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.8);
    color: white;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin-right: 25%;
  }
  
  .close-button {
    background: none;
    border: none;
    font-size: 24px;
    color: white;
    cursor: pointer;
    margin-right: 9px;
    margin-bottom: 3px;
  }
  
  .modal-content-students {
    padding: 20px;
    height: 70%;
    overflow-y: scroll;
  }
  
  .student-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .student-table th,
  .student-table td {
    padding: 10px;
    text-align: left;
  }
  
  .student-table th {
    background-color: #34495e;
  }
  
  .student-table tr:nth-child(even) {
    background-color: #3b4a59;
  }
  
  .modal-footer {
    display: flex;
    justify-content: flex-end;
    padding: 10px 20px;
    background-color: #34495e;
  }
  
  .cancel-button,
  .confirm-button {
    padding: 10px 15px;
    margin-left: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .cancel-button {
    background-color: #e74c3c;
    color: white;
  }
  
  .confirm-button {
    background-color: #2ecc71;
    color: white;
  }
  

  @media (max-width: 768px) {
    .student-selection-modal {
      width: 90%;
      margin-left:5%;
      margin-right: 5%;
    }
  }