.attachments-tab {
    padding: 20px;
    background-color: #2c3d50;
    border-radius: 8px;
  }
  
  .attachments-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .upload-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .no-attachments {
    text-align: center;
    padding: 40px 0;
  }
  
  .icon {
    font-size: 48px;
    margin-bottom: 10px;
  }
  
  .file-list {
    list-style-type: none;
    padding: 0;
  }
  
  .file-list li {
    background-color: #34495e;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 5px;
  }

  @media (max-width: 768px) {
    .attachments-tab{
      transform: scale(0.8);
    }
    .tab-menu-vertical {
      width: 25%;
    }

    .tab-menu-vertical button{
      font-size: 14px;
      padding-left: 5px;
      margin-left: 0px;
    }
    .attachments-tab {
      padding: 0px;
    }
    .attachments-header{
      margin-bottom: 0;
    }
  }