@media (max-width: 768px) {
  .mobile-tabs {
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #34495e;
    padding: 10px 0;
    z-index: 1000;
  }

  .mobile-tab-button {
    color: white;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }

  .mobile-tab-button.active {
    color: #e67e22;
  }
}

@media (min-width: 769px) {
  .mobile-tabs {
    display: none;
  }
}
