/* src/AssessmentTypeSelectionModal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: flex-end;
    /* align-items: center; */
    z-index: 1000;
  }
  
  .selection-modal {
    /* background: #2c3e50; */
    border-radius: 8px;
    width: 400px;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); */
    padding-top: 65px;
    color: white;
    position: relative;
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .modal-description {
    background-color: #f39c12;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 15px;
    margin-right: 40px;
    color: black;
  }
  
  .close-button {
    background: none;
    border: none;
    font-size: 24px;
    color: white;
    cursor: pointer;
  }
  
  .assessment-types {
    list-style: none;
    padding: 0;
    padding-right: 20px;
    margin: 0;
  }
  
  .assessment-item {
    margin-bottom: 25px;
    margin-right: 15px;
    display: flex;
    justify-content: flex-end;

  }
  
  .assessment-button {
    width: 50px;
    background-color: #34495e;
    border: none;
    color: white;
    padding: 10px;
    border-radius: 25px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .assessment-button .icon {
    margin-right: 5px;
    padding-left: 5px;
    font-size: 20px;
  }

  
  @media (max-width: 768px) {
    .assessment-modal{
      margin-left:2%;
      margin-right:2%;
      width: 96%;
      max-height: 80vh;
    }
  }