/* src/styles.css */
html, body, #root {
  height: 100%;
  margin: 0;
  font-family: Arial, sans-serif;
}

body {
  display: flex;
  flex-direction: column;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.semesters-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  /* padding: 20px; */
  max-width: 100%;
  height: 100%;
  margin: 0 auto;
  overflow-y: auto; /* Allow scrolling if content overflows */
}

.semester-card {
  margin-bottom: 10px;
  padding-top: 15px;
  border-radius: 8px;
  /* background-color: #f7f7f7; */
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  cursor: pointer;
  /* transition: background-color 0.3s; */
}


.semester-card.expanded {
  /* background-color: #e9f5fc; */
  /* height: 100vh; */
}

.semester-header {
  /* font-weight: bold; */
  font-size: 16px;
  /* color: #333; */
  justify-content: center;
  text-align: center;
}


.class-card:hover {
  background-color: #f0f0f0;
}

.add-assessment-button {
  position: absolute;
  right: 20px;
  top: 110px;
  padding: 5px 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 50%;
  font-size: 1.2em;
  cursor: pointer;
}