/* src/StudentModal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-student {
    background: white;
    border-radius: 8px;
    width: 500px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    padding: 20px;
    position: relative;
    margin-top: 10%;
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  textarea {
    width: 100%;
    height: 150px;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    font-size: 14px;
    resize: none;
  }
  
  .modal-footer {
    display: flex;
    justify-content: flex-end;
  }
  
  button {
    padding: 10px 20px;
    margin-left: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .close-button-student {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    /* background-color: black; */
  }
  
  .add-button {
    background-color: #2c3e50;
    color: white;
  }
  